import React from "react";
import { Helmet } from "react-helmet";

export default function LcIntercomScripts() {
  return (
    <Helmet>
      <script>
        {`
            window.intercomSettings = {
              app_id: "gip1n6e9"
            };
            
            // this is a modified version of the standard intercom loading script;
            // modification is such that we can load it AFTER everything else has loaded
            
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function() {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function(args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/gip1n6e9';
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                l();
            }            
          `}
      </script>
    </Helmet>
  );
}
