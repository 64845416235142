import React, { useState } from "react";
import { Link } from "gatsby";
import * as c from "./lcNavigationMobile.module.scss";
import classNames from "classnames";
import { Helmet } from "react-helmet";

export default function LcNavigationMobile({ isMenuOpen, onMenuToggle, data }) {
  const [expandedPath, setExpandedPath] = useState("");

  const getIsPathExpanded = (path) => expandedPath.slice(6).includes(path.slice(6));
  const getParentPath = (path) => path.substring(0, path.lastIndexOf("/"));

  const expandPath = (path) => {
    const pathToExpand = path === expandedPath ? getParentPath(path) : path;
    setExpandedPath(pathToExpand);
  };

  const List = ({ className, listItems, depth = 2, expanded, children }) => (
    <ol className={classNames(c.list, className, { [c.isExpanded]: expanded })}>
      {children}
      {listItems.map(({ title, path, items }) => (
        <React.Fragment key={path}>
          {items ? (
            <ListItemExpandable title={title} path={path} depth={depth}>
              <List listItems={items} depth={depth + 1} expanded={getIsPathExpanded(path)}>
                <ListItem>
                  <LinkLabel to={getParentPath(path)} label="Overview" Tag={`h${depth}`} />
                </ListItem>
              </List>
            </ListItemExpandable>
          ) : (
            <ListItem expanded={getIsPathExpanded(path)}>
              <LinkLabel to={path} label={title} Tag={`h${depth}`} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </ol>
  );

  const ListItemExpandable = ({ title, path, depth = 2, children }) => (
    <ListItem expanded={getIsPathExpanded(path)}>
      <ButtonLabel label={title} Tag={`h${depth}`} onClick={() => expandPath(path)} />
      {children}
    </ListItem>
  );

  const ListItem = ({ expanded, children }) => (
    <li className={classNames(c.listItem, { [c.isExpanded]: expanded })}>{children}</li>
  );

  const ButtonLabel = ({ label, Tag, onClick }) => (
    <button className={classNames(c.button)} onClick={onClick}>
      <Tag className={c.label}>{label}</Tag>
      <span className={c.arrow} role="img" aria-label="Expand" />
    </button>
  );

  const LinkLabel = ({ to, label, Tag }) => (
    <Link className={classNames(c.link)} to={to} onClick={onMenuToggle}>
      <Tag className={c.label}>{label}</Tag>
    </Link>
  );

  return (
    <>
      <Helmet htmlAttributes={{ class: classNames({ [c.mobileMenuActive]: isMenuOpen }) }} />

      {isMenuOpen && (
        <nav
          id="mobile-navigation"
          className={classNames(c.navigation, { [c.open]: isMenuOpen })}
          aria-label="Mobile navigation"
          role="navigation"
        >
          {data.map(({ path, items }) => (
            <React.Fragment key={path}>
              <List className={c.section} listItems={items} expanded />
            </React.Fragment>
          ))}
        </nav>
      )}
    </>
  );
}
