export { wrapRootElement, wrapPageElement } from "./gatsby-shared";

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Override gatsby-plugin-canonical-urls clientside logic for referral pages. See gatsby-ssr.js for context.
  const referralBasePath = /^\/referral\/([^\/]+)/;
  const isReferralPath = referralBasePath.test(location.pathname);
  if (isReferralPath) {
    const canonicalLink = document.querySelector(`link[rel='canonical']`);
    canonicalLink && canonicalLink.setAttribute(`href`, `https://www.mangomint.com/`);
  }

  window.setTimeout(() => {
    // adding a 100ms delay because that's apparently what the gatsby-plugins do
    // see here for example: https://github.com/benjaminhoffman/gatsby-plugin-segment-js/blob/master/src/gatsby-browser.js

    // Segment
    window.analytics && window.analytics.page(document.title);

    // Drift
    window.drift && window.drift.page();

    // Wisepops
    window.wisepops && window.wisepops("pageview");
  }, 100);
};

/*
  Gatsby is inconsistently setting the document scroll position when navigating to a URL containing a
  fragment identifier, so we override the default srcoll behavior in order to fix it. We only do this
  for learning center pages since currently these are the only pages that are linked to in this way.
  We continue to allow Gatsby to handle the scroll position on all other pages in order to minimize
  any potential defects that might be introduced as a result of modifying this behavior.

  Returns one of:
    Boolean  -  true    -  Let Gatsby update the scroll position
    Boolean  -  false   -  Do not update the scroll position, handle it manually instead
  
  It is also possible to return an [x, y] array to specify the scroll position directly, but this
  is intentionally not used here since it results in the same scroll issue that we are trying to fix.
*/
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // Let Gatsby handle the default scroll position if...
  // Path is not a learning center path
  const isLearningCenterPath = location.pathname && location.pathname.startsWith("/learn/");
  if (!isLearningCenterPath) return true;

  // Path does not contain a fragment identifier
  const fragment = location.hash;
  if (!fragment) return true;

  // A corresponding element does not exist in the DOM
  const elementId = window.decodeURI(fragment.replace("#", ""));
  const element = document.getElementById(elementId);
  if (!element) return true;

  // Otherwise, handle the scroll position manually
  setTimeout(() => {
    window.history.scrollRestoration = "manual";

    // Before next paint, attempt to calculate element's Y position and then scroll to it
    requestAnimationFrame(() => {
      const defaultScrollPosition = [0, 0];
      const currentScrollPosition = getSavedScrollPosition(location) || defaultScrollPosition;
      const fragmentScrollPosition = [currentScrollPosition[0], getElementAbsPosY(element)];

      window.scrollTo(...fragmentScrollPosition);
    });
  }, 100);

  return false;
};

const getElementAbsPosY = (element) => {
  // The element's bounding rectangle is relative to the viewport, but we want to know it's absolute
  // position in the document. We can calculate this by adding the current document scroll position to
  // the element's relative position in the viewport. We also want to check if the element has any styling
  // applied that would affect it's scroll position (e.g. scroll-margin-top), and if so, subtract it from
  // the final result so that the CSS property is respected.
  const documentScrollAbsPosY = window.scrollY;
  const elementRelPosY = element.getBoundingClientRect().top;
  const elementScrollMarginTop =
    parseInt(window.getComputedStyle(element, null).getPropertyValue(`scroll-margin-top`)) || 0;

  const elementAbsPosY = elementRelPosY + documentScrollAbsPosY - elementScrollMarginTop;

  return elementAbsPosY;
};
