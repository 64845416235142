// Once we have a better understanding of the analytics tools we want to use, we can rename
// these functions and consolidate this file so we have a single event layer for analytics.

export const trackEvent = (event, properties) => {
  window?.analytics && window.analytics.track(event, properties);
  window?.posthog && window.posthog.capture(event, properties);
};

export const identify = (properties) => {
  window?.analytics && window.analytics.identify(properties);

  if (window?.posthog) {
    const phDistinctId = window.posthog.get_distinct_id();
    window.posthog.identify(phDistinctId, properties);
  }
};

export const trackMntnConversion = () =>
  (function () {
    var x = null,
      p,
      q,
      m,
      o = "35155",
      l = "ORDER ID",
      i = "TOTAL ORDER AMOUNT",
      c = "",
      k = "",
      g = "",
      j = "",
      u = "",
      shadditional = "";
    try {
      p = top.document.referer !== "" ? encodeURIComponent(top.document.referrer.substring(0, 512)) : "";
    } catch (n) {
      p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : "";
    }
    try {
      q =
        window && window.top && document.location && window.top.location === document.location
          ? document.location
          : window && window.top && window.top.location && "" !== window.top.location
          ? window.top.location
          : document.location;
    } catch (b) {
      q = document.location;
    }
    try {
      m = parent.location.href !== "" ? encodeURIComponent(parent.location.href.toString().substring(0, 512)) : "";
    } catch (z) {
      try {
        m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : "";
      } catch (h) {
        m = "";
      }
    }
    var A,
      y = document.createElement("script"),
      w = null,
      v = document.getElementsByTagName("script"),
      t = Number(v.length) - 1,
      r = document.getElementsByTagName("script")[t];
    if (typeof A === "undefined") {
      A = Math.floor(Math.random() * 100000000000000000);
    }
    w =
      "dx.mountain.com/spx?conv=1&shaid=" +
      o +
      "&tdr=" +
      p +
      "&plh=" +
      m +
      "&cb=" +
      A +
      "&shoid=" +
      l +
      "&shoamt=" +
      i +
      "&shocur=" +
      c +
      "&shopid=" +
      k +
      "&shoq=" +
      g +
      "&shoup=" +
      j +
      "&shpil=" +
      u +
      shadditional;
    y.type = "text/javascript";
    y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w;
    r.parentNode.insertBefore(y, r);
  })();

export const trackBingConversion = (event, properties = {}) => {
  window.uetq = window.uetq || [];
  window.uetq.push("event", event, properties);
};
