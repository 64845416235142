import { useEffect } from "react";

const defaultEvents = ["mousedown", "touchstart"];

/**
 * This hook listens for click events that occur anywhere on the page
 * except on the element ref passed. Note that the callback
 */
export const useOnClickOutside = (ref, callback, events = defaultEvents) => {
  useEffect(() => {
    const listener = (e) => {
      const elementExists = ref.current;
      const elementWasNotClicked = !ref.current.contains(e.target);

      if (elementExists && elementWasNotClicked) {
        callback(e);
      }
    };

    events.forEach((event) => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [ref, callback]);
};
