import React from "react";
import * as c from "./lcSidebarSubcategoryArticle.module.scss";
import { Link } from "gatsby";
import classNames from "classnames";

export default function LcSidebarSubcategoryArticle({ title, slug, isArticleActive }) {
  return (
    <Link className={classNames(c.link, { [c.active]: isArticleActive })} to={`/learn/${slug}/`}>
      {title}
    </Link>
  );
}
