import React, { forwardRef } from "react";
import * as c from "./button.module.scss";
import classNames from "classnames";
import { Link } from "gatsby";

const Button = forwardRef(
  (
    {
      className,
      size = "medium",
      variant = "full",
      isExternal = false,
      asHtmlButton = false,
      animated = false,
      linkTo = "",
      children,
      buttonStyle,
      ...props
    },
    ref
  ) => {
    const cx = classNames(
      className,
      c.button,
      c[variant],
      c[size],
      animated && c.animated,
      buttonStyle === "outline" && c.outline
    );

    if (asHtmlButton) {
      return (
        <button ref={ref} className={cx} {...props}>
          {children}
        </button>
      );
    } else if (isExternal) {
      return (
        <a ref={ref} className={cx} target="_blank" href={linkTo} {...props}>
          {children}
        </a>
      );
    } else if (animated) {
      return (
        <Link ref={ref} className={cx} to={linkTo} {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <Link ref={ref} className={cx} to={linkTo} {...props}>
          {children}
        </Link>
      );
    }
  }
);

export { Button };
