import React, { useState, useEffect } from "react";
import * as c from "./lcSidebarCategorySubitem.module.scss";
import { Link } from "gatsby";
import LcSidebarSubcategoryArticle from "./lcSidebarSubcategoryArticle";
import classNames from "classnames";

export default function LcSidebarCategorySubitem({
  item,
  sectionSlug,
  categorySlug,
  isSubCategoryActive,
  activeArticleSlug,
}) {
  // "isSelfActive" means that the category article itself is actively shown
  // "open" just means that either the category article OR an subitem is shown
  const isSelfActive = isSubCategoryActive && (!activeArticleSlug || activeArticleSlug === item.slug);

  const [isOpen, setIsOpen] = useState(isSubCategoryActive);

  useEffect(() => {
    setIsOpen(isSubCategoryActive);
  }, [isSubCategoryActive]);

  const isArticleActive = ({ slug }) => slug === activeArticleSlug;
  const isAnyArticleActive = item.items?.some(isArticleActive);

  const handleOnClick = (e) => {
    if (!isAnyArticleActive) {
      setIsOpen(!isOpen);
    } else if ((!isOpen && isSubCategoryActive) || isAnyArticleActive) {
      setIsOpen(true);
    } else {
      e.preventDefault();
      setIsOpen(false);
    }
  };

  const itemType = item.sys.contentType.sys.id;

  let linkEl;
  let itemsEl;
  if (itemType === "lcSubcategory") {
    linkEl = (
      <Link
        className={c.head}
        to={`/learn/${sectionSlug}/${categorySlug}/${item.slug}/`}
        onClick={(e) => handleOnClick(e)}
      >
        <div className={c.title}>
          {item.title}
          <i className={c.arrow} />
        </div>
      </Link>
    );

    const items = (item.items || []).map((item, idx) => {
      return (
        <LcSidebarSubcategoryArticle
          key={idx}
          title={item.title}
          slug={item.slug}
          isArticleActive={isArticleActive(item)}
        />
      );
    });

    itemsEl = <div className={c.items}>{items}</div>;
  } else {
    // item is an article
    linkEl = (
      <Link className={c.head} to={`/learn/${item.slug}/`}>
        <div className={c.title}>{item.title}</div>
      </Link>
    );
  }

  return (
    <div className={classNames(c.container, { [c.open]: isOpen, [c.selfActive]: isSelfActive })}>
      {linkEl}
      {itemsEl}
    </div>
  );
}
