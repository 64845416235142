import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

export default connectSearchBox(({ refine, className, inputRef, currentRefinement }) => (
  <input
    className={className}
    ref={inputRef}
    value={currentRefinement}
    type="search"
    placeholder="Search"
    onChange={(e) => refine(e.target.value)}
  />
));
