import React, { useState, useEffect } from "react";
import * as c from "./lcSearchResults.module.scss";
import { Highlight, Snippet, connectStateResults, connectHits } from "react-instantsearch-dom";
import { Link } from "gatsby";

const Results = connectStateResults(({ searchState, searchResults, children }) => {
  const searchResultsFound = searchResults?.nbHits > 0;

  if (searchResultsFound) {
    return children;
  }

  return <div className={c.noResults}>No results for '{searchState.query}'</div>;
});

const Hits = connectHits(({ hits }) => {
  return (
    <ol className={c.hits}>
      {hits.map((hit) => (
        <li className={c.hit} key={hit.id}>
          <Link className={c.link} to={hit.path}>
            <Highlight className={c.title} hit={hit} attribute="title" />
            <div className={c.breadcrumbs}>{hit.breadcrumbs}</div>
            <Snippet className={c.body} hit={hit} attribute="body">
              <Highlight hit={hit} attribute="body" />
            </Snippet>
          </Link>
        </li>
      ))}
    </ol>
  );
});

export default function LcSearchResults({ searchState, isSearchFocused }) {
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);

  useEffect(() => {
    const hasClientProvidedQuery = searchState?.query?.length > 0;
    const shouldRenderResults = isSearchFocused && hasClientProvidedQuery;

    setShouldRenderComponent(shouldRenderResults);
  }, [searchState, isSearchFocused]);

  return (
    <>
      {shouldRenderComponent && (
        <div className={c.container} tabIndex="0">
          <Results>
            <Hits />
          </Results>
        </div>
      )}
    </>
  );
}
