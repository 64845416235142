import React from "react";
import * as c from "./lcLayout.module.scss";
import LcNavbar from "./lcNavbar";
import GlobalHtmlHead from "../layout/globalHtmlHead";
import LcSidebar from "./lcSidebar";
import { Helmet } from "react-helmet";
import LcIntercomScripts from "./lcIntercomScripts";

export default function LcLayout({ children, location, pageContext }) {
  const { sectionSlug, categorySlug, subcategorySlug, articleSlug, navigationData } = pageContext;
  const isLandingPage = location?.pathname === "/learn/";

  return (
    <>
      <GlobalHtmlHead />
      <LcIntercomScripts />

      <Helmet bodyAttributes={{ class: c.lcNonHomeBody }} />

      {/*This fixes an insanely stupid Gatsby bug. Daniel on 6/14/2020 */}
      <div className={c.dummy} />

      <LcNavbar navigationData={navigationData} />

      <div className={c.layoutWrapper}>
        <LcSidebar
          className={c.sidebar}
          activeSectionSlug={sectionSlug}
          activeCategorySlug={categorySlug}
          activeSubcategorySlug={subcategorySlug}
          activeArticleSlug={articleSlug}
          navigationData={navigationData}
          isLandingPage={isLandingPage}
        />
        <main className={c.main}>{children} </main>
      </div>
    </>
  );
}
