import React, { useState, useCallback, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import * as c from "./lcNavbar.module.scss";
import LcSearch from "./lcSearch";
import { MangomintLogo } from "../../components/ui/mangomintLogo";
import LcNavigationMobile from "./lcNavigationMobile";
import { useLocation } from "@reach/router";

export default function LcNavbar({ navigationData }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const keyboardFunctions = useCallback((event) => {
    const { key, keyCode } = event;
    if (key === "Escape" || keyCode === 27) {
      setIsMobileMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyboardFunctions, false);

    return () => {
      document.removeEventListener("keydown", keyboardFunctions, false);
    };
  }, []);

  // Close mobilemenu when navigating to a new page
  let currentRoute = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [currentRoute]);

  return (
    <header className={c.header}>
      <nav className={classNames(c.nav, { [c.showInMobileMenu]: isMobileMenuOpen })}>
        <div className={c.container}>
          <MangomintLogo className={classNames(c.logo)} inverted={isMobileMenuOpen} iconOnlyMobile />
          <Link className={c.lcLogoLink} to="/learn/">
            Learning Center
          </Link>
          <LcSearch className={classNames(c.searchCtn)} />
          <button
            className={c.mobileMenuToggler}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            type="button"
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-navigation"
            aria-label="Toggle mobile navigation"
          >
            <span
              className={classNames(c.mobileMenuTogglerIcon, { [c.showInMobileMenu]: isMobileMenuOpen })}
              role="img"
            />
          </button>
        </div>
        <LcNavigationMobile
          data={navigationData}
          isMenuOpen={isMobileMenuOpen}
          onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />
      </nav>
    </header>
  );
}
