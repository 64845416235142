// extracted by mini-css-extract-plugin
export var arrow = "lcNavigationMobile-module--arrow--4991a";
export var button = "lcNavigationMobile-module--button--5d4dc";
export var isExpanded = "lcNavigationMobile-module--isExpanded--1b19f";
export var label = "lcNavigationMobile-module--label--3361a";
export var link = "lcNavigationMobile-module--link--3c941";
export var list = "lcNavigationMobile-module--list--ee731";
export var listItem = "lcNavigationMobile-module--listItem--1687f";
export var mobileMenuActive = "lcNavigationMobile-module--mobileMenuActive--991c2";
export var navigation = "lcNavigationMobile-module--navigation--c3d9e";
export var open = "lcNavigationMobile-module--open--f7b1e";
export var section = "lcNavigationMobile-module--section--d8bed";