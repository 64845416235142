import React, { createRef, useState, useEffect } from "react";
import * as c from "./lcSearch.module.scss";
import IconSearch from "../icons/iconSearch";
import classNames from "classnames";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import LcSearchInput from "./lcSearchInput";
import LcSearchResults from "./lcSearchResults";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useLocation } from "@reach/router";

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);

export default function LcSearch({ className }) {
  const searchInitialState = {};
  const [searchState, setSearchState] = useState(searchInitialState);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const searchContainerRef = createRef();
  const searchInputRef = createRef();

  // This ensures that the search input focus is correctly updated
  // if a user clicks the search before react hydrates the page and
  // begins listening to the onFocus/onBlur input events.
  useEffect(() => {
    const isSearchInputNodeFocused = searchInputRef.current.contains(document.activeElement);
    const isDocumentFocused = document.hasFocus();

    if (isDocumentFocused && isSearchInputNodeFocused) {
      setIsSearchFocused(true);
    }
  }, []);

  useOnClickOutside(searchContainerRef, () => {
    setIsSearchFocused(false);
  });

  // Close search results when navigating to a new page
  let currentRoute = useLocation();

  useEffect(() => {
    setIsSearchFocused(false);
  }, [currentRoute]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_SEARCH_INDEX}
      searchState={searchState}
      onSearchStateChange={(searchState) => setSearchState(searchState)}
    >
      <section
        role="search"
        className={classNames(c.container, className)}
        ref={searchContainerRef}
        onFocus={() => setIsSearchFocused(true)}
      >
        <IconSearch className={c.icon} />
        <LcSearchInput className={c.searchInput} inputRef={searchInputRef} />
        <LcSearchResults searchState={searchState} isSearchFocused={isSearchFocused} />
      </section>
    </InstantSearch>
  );
}
