import React from "react";
import * as c from "./lcSidebar.module.scss";
import LcSidebarCategory from "./lcSidebarCategory";
import classNames from "classnames";

export default function LcSidebar({
  className,
  activeSectionSlug,
  activeCategorySlug,
  activeSubcategorySlug,
  activeArticleSlug,
  navigationData,
  isLandingPage,
}) {
  if (!navigationData) {
    return null;
  }

  const activeSection = navigationData.find(({ slug }) => slug === activeSectionSlug);
  if (!activeSection && !isLandingPage) {
    // this could be the case if we're directly accessing (via search for example)
    // and article that has a parent that is not published. in this case, we should soft-ish fail
    return null;
  }

  return (
    <div className={classNames(c.container, className)}>
      <div className={c.inner}>
        {navigationData.map(({ items, slug, title }) => (
          <React.Fragment key={slug}>
            <span className={c.section}>Select a topic:</span>
            {items.map((item) => (
              <LcSidebarCategory
                key={slug + item.slug}
                title={item.title}
                slug={item.slug}
                items={item.items}
                sectionSlug={slug}
                isCategoryActive={item.slug === activeCategorySlug}
                activeCategorySlug={activeCategorySlug}
                activeSubcategorySlug={activeSubcategorySlug}
                activeArticleSlug={activeArticleSlug}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
