// extracted by mini-css-extract-plugin
export var icon = "mangomintLogo-module--icon--94567";
export var iconOnlyMobile = "mangomintLogo-module--iconOnlyMobile--4a08e";
export var inverted = "mangomintLogo-module--inverted--88f8c";
export var left = "mangomintLogo-module--left--b763e";
export var mangomintLogo = "mangomintLogo-module--mangomintLogo--78694";
export var mangomintLogoSource = "mangomintLogo-module--mangomintLogoSource--ac053";
export var middle = "mangomintLogo-module--middle--9bb63";
export var right = "mangomintLogo-module--right--473bd";
export var text = "mangomintLogo-module--text--2a95c";
export var wrapper = "mangomintLogo-module--wrapper--c9dc6";