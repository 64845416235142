import React, { useState, useEffect } from "react";
import * as c from "./lcSidebarCategory.module.scss";
import { Link } from "gatsby";
import classNames from "classnames";
import LcSidebarCategorySubitem from "./lcSidebarCategorySubitem";

export default function LcSidebarCategory({
  slug,
  title,
  items,
  sectionSlug,
  isCategoryActive,
  activeSubcategorySlug,
  activeArticleSlug,
}) {
  // "isSelfActive" means that the category article itself is actively shown
  // "open" just means that either the category article OR an subitem is shown
  const [isOpen, setIsOpen] = useState(isCategoryActive);
  const isSelfActive = isCategoryActive && !activeSubcategorySlug && !activeArticleSlug;

  useEffect(() => {
    setIsOpen(isCategoryActive);
  }, [isCategoryActive]);

  const isSubCategoryActive = ({ slug }) =>
    slug === activeSubcategorySlug || (!activeSubcategorySlug && slug === activeArticleSlug);

  const itemEls = (items || []).map((item, idx) => {
    return (
      <LcSidebarCategorySubitem
        item={item}
        sectionSlug={sectionSlug}
        categorySlug={slug}
        key={idx}
        isSubCategoryActive={isSubCategoryActive(item)}
        activeArticleSlug={activeArticleSlug}
      />
    );
  });

  return (
    <div className={classNames(c.category, { [c.open]: isOpen, [c.selfActive]: isSelfActive })}>
      <Link className={c.head} to={`/learn/${sectionSlug}/${slug}/`} onClick={(e) => setIsOpen(!isOpen)}>
        <div className={c.title}>
          {title}
          {itemEls.length > 0 && <i className={c.arrow} />}
        </div>
      </Link>
      <div className={c.items}>{itemEls}</div>
    </div>
  );
}
