import React, { useEffect, useState, useRef } from "react";
import FontFaceObserver from "fontfaceobserver";
import * as c from "./fadeInOnFontLoad.module.scss";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

const FadeInOnFontLoad = ({ children }) => {
  // Could be further enhanced to eliminate page transitions on
  // subsequent visits by setting localstorage instead of useState
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  // This is only called once on the first page render when
  // visiting from a different domain
  useEffect(() => {
    if (typeof window !== `undefined` && !isFontLoaded) {
      const font = new FontFaceObserver("tt-commons"); // This only listens for the non-italic font to complete
      font
        .load(null, 3000) // Timeout after 3 seconds
        .then(() => setIsFontLoaded(true))
        .catch(() => {
          console.error(`An error occurred during font loading`);
          setIsFontLoaded(true);
          // Font-loading failed, but we should still render the page with the fallback font
        });
    }
  }, [isFontLoaded]);

  const ref = useRef(null);

  const pageTransitionDelay = 4 / 30; // Delay slightly to allow browser more time to load content
  const pageTransitionDuration = 6 / 30 + pageTransitionDelay;

  useGSAP(() => {
    if (isFontLoaded) {
      gsap.to(ref.current, { opacity: 0, duration: pageTransitionDuration, delay: pageTransitionDelay });
      gsap.to(ref.current, {
        zIndex: -99999,
        pointerEvents: "none",
        display: "none",
        visibilty: "hidden",
        duration: 1 / 30,
        delay: pageTransitionDuration,
      });
    }
  }, [isFontLoaded]);

  return (
    <>
      <div ref={ref} className={c.overlay}></div>
      {children}
    </>
  );
};

export default FadeInOnFontLoad;
