import React from "react";

export default function IconSearch({ className }) {
  return (
    <svg className={className} viewBox="0 0 20 20">
      <path
        d="M13.596 2.292a7.826 7.826 0 10-.65 11.65l5.571 5.57a.866.866 0 001.227-.002.867.867 0 00.003-1.228l-5.57-5.57a7.827 7.827 0 00-.58-10.42zm-9.838 1.23a6.087 6.087 0 118.609 8.608 6.087 6.087 0 01-8.609-8.608z"
        fillRule="nonzero"
      />
    </svg>
  );
}
